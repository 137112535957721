<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_28_32)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M128 19.6923C99.275 19.6923 71.7265 31.1033 51.4149 51.4149C31.1033 71.7265 19.6923 99.275 19.6923 128C19.6923 142.223 22.4938 156.307 27.9367 169.448C33.3797 182.588 41.3576 194.528 51.4149 204.585C61.4722 214.642 73.412 222.62 86.5524 228.063C99.6929 233.506 113.777 236.308 128 236.308C142.223 236.308 156.307 233.506 169.448 228.063C182.588 222.62 194.528 214.642 204.585 204.585C214.642 194.528 222.62 182.588 228.063 169.448C233.506 156.307 236.308 142.223 236.308 128C236.308 99.275 224.897 71.7265 204.585 51.4149C184.273 31.1033 156.725 19.6923 128 19.6923ZM37.4903 37.4903C61.495 13.4857 94.0523 0 128 0C161.948 0 194.505 13.4857 218.51 37.4903C242.514 61.495 256 94.0523 256 128C256 144.809 252.689 161.454 246.257 176.983C239.824 192.513 230.396 206.624 218.51 218.51C206.624 230.396 192.513 239.824 176.983 246.257C161.454 252.689 144.809 256 128 256C111.191 256 94.5462 252.689 79.0165 246.257C63.4868 239.824 49.3762 230.396 37.4903 218.51C25.6044 206.624 16.176 192.513 9.74342 176.983C3.31082 161.454 0 144.809 0 128C0 94.0523 13.4857 61.495 37.4903 37.4903ZM128 65.641C133.438 65.641 137.846 70.0493 137.846 75.4872V123.922L174.347 160.422C178.192 164.268 178.192 170.502 174.347 174.347C170.502 178.192 164.268 178.192 160.422 174.347L121.038 134.962C119.191 133.116 118.154 130.611 118.154 128V75.4872C118.154 70.0493 122.562 65.641 128 65.641Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
